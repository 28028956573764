import { Component } from "react";
import { Link } from "react-router-dom";

class Header extends Component {
    render() 
    {
        return (
            <header id="header">
                <div>
                    <Link to="https://victoriavr.com">
                        <img
                            src="./VictoriaVRLogo.svg" 
                            alt="logo" height="48" width="256" loading="lazy"> 
                        </img>  
                    </Link>
                    <div id="header-right">
                        {!this.props.walletAddress ?
                            <button className="connect button" onClick={async (e) => this.props.connect()}>
                                CONNECT METAMASK
                            </button>
                        : 
                            <ul id="header-menu">
                                <li> <Link to="/your-stakes"> Your stakes </Link> </li>
                                <li> <a href="https://forms.office.com/e/EU8axwYUpX" target={"_blank"}> Application </a> </li>
                            </ul>
                        }               
                    </div>
                </div>
            </header>
        )
    }
}

export default Header;